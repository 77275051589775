<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
    <template v-for="(socialMedia, n) in socialMedias" :key="n">
      <component :is="socialMedia"/>
    </template>
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const socialMedias = [
      // defineAsyncComponent(() => import('@/components/social-media/FacebookModal.vue')),
      // defineAsyncComponent(() => import('@/components/social-media/InstagramModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/MetaModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/LinkedinModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/TwitterModal.vue')),
    ]

    return {
      socialMedias
    }
  }
})
</script>

<style scoped>

</style>