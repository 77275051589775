
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const socialMedias = [
      // defineAsyncComponent(() => import('@/components/social-media/FacebookModal.vue')),
      // defineAsyncComponent(() => import('@/components/social-media/InstagramModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/MetaModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/LinkedinModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/TwitterModal.vue')),
    ]

    return {
      socialMedias
    }
  }
})
